<template>
  <div class="mt-5">
    <div class="page-content-inner pt-lg-0 pr-lg-0 mt-4">
      <div class="uk-grid-medium" uk-grid>
        <div class="section-header pb-0 mt-2 uk-width-4-4@m">
          <div class="section-header-left">
            <h3 class="uk-text-truncate"><i class="uil-book-open"></i> {{ $t('sideNav.My_education') }}</h3>
          </div>
        </div>
        <div class="uk-width-3-4">
          <div class="section-header pb-0">
            <div class="section-header-left">
              <form action="#">
                <input v-model="searchQuery" :placeholder="$t('education.search')" type="text" @input="isTyping = true">
              </form>
            </div>
            <div class="section-header-right ">
              <div class="display-as ">
                <a :class="{ 'active': activeTab == 1 }" :uk-tooltip="`title: ${$t('home.Card')} ; pos: top-right`"
                  @click.prevent="changeToList">
                  <i class="icon-feather-grid"></i></a>
                <a :class="{ 'active': activeTab == 2 }" :uk-tooltip="`title: ${$t('home.List')} ; pos: top-right`"
                  @click.prevent="changeToCard">
                  <i class="icon-feather-list"></i></a>
              </div>
              <multiple-select-input :model.sync="filters.order_by" :multiple="false" :required="false"
                :searchable="false" :showLabels="false" :values="filterTypes" :allowEmpty="false"
                @select="filters.order_by = $event; applyFilter()" />
            </div>
            <nav class="responsive-tab style-2">
              <ul>
                <li><a :class="{ active: filters.just_assigned === true }" href="javascript:void(0)"
                    @click=" setFilterItem('just_assigned', true)"> {{ $t('education.new_assigned_education') }}
                    <div class="star-rating" v-if="!Object(assigmentCounts).length"><span class="avg"> {{
                      assigmentCounts.just_assigned }} </span></div>
                  </a>
                </li>
                <li><a :class="{ active: filters.on_progress === true }" href="javascript:void(0)"
                    @click=" setFilterItem('on_progress', true)"> {{ $t('education.ongoing_education') }}
                    <div class="star-rating" v-if="!Object(assigmentCounts).length"><span class="avg"> {{
                      assigmentCounts.on_progress }} </span></div>
                  </a></li>
                <li><a :class="{ active: filters.completed === true }" href="javascript:void(0)"
                    @click=" setFilterItem('completed', true)"> {{ $t('education.completed_education') }}
                    <div class="star-rating" v-if="!Object(assigmentCounts).length"><span class="avg"> {{
                      assigmentCounts.completed }} </span></div>
                  </a></li>
                <li><a :class="{ active: filters.repeat_it === true }" href="javascript:void(0)"
                    @click=" setFilterItem('repeat_it', true)"> {{ $t('education.repeat_education') }}
                    <div class="star-rating" v-if="!Object(assigmentCounts).length"><span class="avg"> {{
                      assigmentCounts.repeat_it }} </span></div>
                  </a></li>
                <li>
                  <a :class="{ active: !filters.just_assigned && !filters.on_progress && !filters.completed && !filters.repeat_it }"
                    href="javascript:void(0)" @click=" setFilterItem('with_expired', false)"> {{
                      $t('education.all_education') }}
                    <div class="star-rating" v-if="!Object(assigmentCounts).length"><span class="avg"> {{
                      assigmentCounts.all }} </span></div>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <default-loading v-if="assignment_loading || page_loading"></default-loading>
          <template v-else>
            <div class="uk-child-width-1-3@m uk-child-width-1-3@s course-card-grid uk-grid-match mt-2" uk-grid>
              <template v-for="(  item, index  ) in sortedAllItems  " v-if="activeTab == 1">
                <education-card :id="item.id" :repeatable="item.repeatable" :order-number="item.order_number"
                  :bottom-title="item.bottom_title" :has-present="item.has_present" :image="item.cover_image_link"
                  :process-width="item.completion_percentage ? item.completion_percentage : 0"
                  :processClass="[(item.completion_percentage >= 75 ? 'bg-success' : ''), (item.completion_percentage < 75 && item.completion_percentage >= 50 ? 'bg-warning' : '')]"
                  :sub-title="item.sub_title" :time="timeFromDate(item.time)" :title="item.name"
                  :is-assignment-end-date-expired="isAssignmentEndDateExpired(item.end_date)" bottom-class=""
                  icon-class="uil-play-circle" links=""
                  @onClickCard=" handleOnClickCard(item.link_type, item.id, isAssignmentEndDateExpired(item.end_date))"></education-card>
              </template>
              <template v-for="(  item, index  ) in sortedAllItems  " v-if="activeTab == 2">
                <education-card-list :id="item.id" :repeatable="item.repeatable" :order-number="item.order_number"
                  :bottom-title="item.bottom_title" :has-present="item.has_present" :image="item.cover_image_link"
                  :process-width="item.completion_percentage ? item.completion_percentage : 0"
                  :processClass="[(item.completion_percentage >= 75 ? 'bg-success' : ''), (item.completion_percentage < 75 && item.completion_percentage >= 50 ? 'bg-warning' : '')]"
                  :sub-title="item.sub_title" :time="timeFromDate(item.time)" :title="item.name"
                  :is-assignment-end-date-expired="isAssignmentEndDateExpired(item.end_date)" bottom-class=""
                  icon-class="uil-play-circle" links="" :is-watchable-again="item.is_watchable_again"
                  @onClickCard=" handleOnClickCard(item.link_type, item.id, isAssignmentEndDateExpired(item.end_date))"></education-card-list>
              </template>
            </div>
            <div class="uk-flex uk-flex-center p-4">
              <button v-if="items.last_page > pageNumber || trainingItems.last_page > pageNumber"
                class="btn btn-info button_more" @click.prevent="itemsMoreButton">{{ $t('general.more') }}
              </button>
            </div>
          </template>
        </div>
        <div class="uk-width-expand">
          <div class="uk-sticky" uk-sticky="offset:100; ; media : @s: bottom: true">
            <h4 style="text-transform: capitalize">
              <i class="il-filter"></i> {{ this.$t('general.filter') }}

            </h4>
            <div class="p-2 sidebar-filter card">
              <div class="sidebar-filter-contents">

                <ul class="sidebar-filter-list uk-accordion" uk-accordion="multiple: true">
                  <li class="uk-open">
                    <a class="uk-accordion-title" href="#">
                      {{ $t('general.by_category_tag') }}
                    </a>
                    <div aria-hidden="false" class="uk-accordion-content">
                      <div class="form-group">
                        <label for="search-tag" style="font-size: 0.75rem"><i class="uil-search"></i>{{
                          this.$t('general.Tag Search') }}</label>
                        <input v-model="searchTag" autocomplete="off" class="form-control mb-0"
                          placeholder="Bir etiket yazın" type="text" />
                      </div>
                      <div class="uk-form-controls" style="overflow-y: scroll; height: 30rem">
                        <label v-for="  item in filteredTags  ">
                          <input v-model="filters.selected_hashtag" :value="item.id" :id="`hastag-` + item.id"
                            class="uk-radio" name="filters" type="radio" @click=" clickedRadioButton($event)"
                            @change=" applyFilter()" />
                          <span class="test font-weight-light"> #{{ item.name }} </span>
                        </label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="uk-sticky-placeholder" hidden="" style="height: 721px; margin: 0px 0px 20px"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EducationCard from "@/view/components/index/EducationCard";
import Pagination from "@/view/components/dataTable/Pagination";
import EducationCardList from "@/view/components/index/EducationCardList";
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/az';
import 'moment/locale/tr';
import 'moment/locale/en-gb';
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import { GET_COUNTS, GET_ITEMS, LOADING } from "@/core/services/store/REST.module";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import _ from "lodash";


import MultipleSelectInput from "@/assets/components/inputs/MultipleSelectInput";
let latestForAssigmentRequest = null;
let latestForTrainingRequest = null;

export default {
  name: "MyEducationOrAssignment",
  components: {
    Multiselect, MultipleSelectInput,
    EducationCard,
    EducationCardList,
    Pagination,
    DefaultLoading
  },
  data() {
    return {
      page_loading: false,
      filterTypes: [
        {
          id: 'name',
          name: this.$t('filter.Filter_by_name')
        },
        {
          id: 'created_at',
          name: this.$t('filter.Filter_by_date')
        },
        /* {
          id: 'category_id',
          name: this.$t('filter.Filter_by_category')
        }, */
      ],
      restUrl: 'api/assignment',
      restTrainingUrl: 'api/training',
      searchQuery: "",
      isTyping: false,
      allItems: [],
      searchResult: [],
      activeTab: 1,
      items: [],
      trainingItems: [],
      hashtags: [],
      searchTag: '',
      recentTagsUrl: 'api/recent_tag',
      defaultFilterOrderBy: { "id": "name", "name": this.$t('filter.Filter_by_name') },
      pageNumber: 1,
      filters: {
        just_assigned: true,
        selected_hashtag: null,
        selected_hashtag_id: null,
        completed: false,
        on_progress: false,
        repeat_it: false,
        with_expired: false,
        by_user: true,
        order_by: { "id": "name", "name": this.$t('filter.Filter_by_name') },
        sort: 'desc',
        perPage: 9,
        search: null,
      },
      trainingFilters: {
        search: '',
        by_user: true,
        training_type_id: 0,
        from_user: true,
        just_assigned: false,
        completed: false,
        on_progress: false,
        repeat_it: false,
        perPage: 9
      },
      assigmentCounts: {},
    };
  },
  computed: {
    ...mapGetters({
      assignment_loading: LOADING
    }),
    filteredTags() {
      const tags = this.hashtags
      const search = this.searchTag
      if (search === '') return tags
      return tags.filter((tag) => {
        return tag.name.toLowerCase().includes(search.toLowerCase());
      });
    },
    sortedAllItems() {
      var orderBy = this.filters.order_by.id
      this.allItems = [];
      if (this.trainingItems.data || this.items.data) {
        if (this.items.data) {
          this.items.data.forEach(element => {
            if (!this.allItems.find(item => item.id == element.id)) {
              this.allItems.push(element)
            }
          });
        }
        if (this.trainingItems.data) {
          this.trainingItems.data.forEach(element => {
            if (!this.allItems.find(trainingItem => trainingItem.id == element.id)) {
              this.allItems.push(element)
            }
          });
        }
      }
      if (orderBy == "name") {
        return this.allItems.sort(function (a, b) {
          return a[orderBy].localeCompare(b[orderBy]);
        });
      }
      return this.allItems.sort((a, b) => a[orderBy] - b[orderBy])
    }
  },
  methods: {
    isAssignmentEndDateExpired(date) {
      return moment(date).isBefore(moment())
    },
    itemsMoreButton() {
      let self = this;
      self.allItems = []
      self.pageNumber++;
      if (self.pageNumber > self.items.last_page && self.pageNumber > self.trainingItems.last_page) {
        return;
      } else {
        let filters = _.clone(self.filters)
        this.$set(filters, 'page', self.pageNumber)
        this.$set(filters, 'order_by', self.filters.order_by.id)
        this.$set(filters, 'perPage', self.filters.perPage)
        this.$set(filters, 'by_user', self.filters.by_user)
        this.$store.dispatch(GET_ITEMS, {
          url: self.restUrl,
          acceptPromise: false,
          showLoading: true,
          filters: filters
        }).then(result => {
          if (result.status) {
            self.addFormattedItem(result.data.data)
          }
        })
      }

      if (this.CatalogTrainingVisible) {
        this.$set(this.trainingFilters, 'page', self.pageNumber)
        this.$set(this.trainingFilters, 'order_by', this.filters.order_by.id)
        this.$set(this.trainingFilters, 'perPage', this.trainingFilters.perPage)
        this.$set(this.trainingFilters, 'by_user', this.trainingFilters.by_user)
        this.$set(this.trainingFilters, 'from_user', this.trainingFilters.from_user)
        this.$set(this.trainingFilters, 'just_assigned', this.trainingFilters.just_assigned)
        this.$set(this.trainingFilters, 'repeat_it', this.trainingFilters.repeat_it)
        this.$store.dispatch(GET_ITEMS, {
          url: this.restTrainingUrl,
          acceptPromise: false,
          showLoading: true,
          filters: this.trainingFilters
        }).then(result => {
          if (result.status) {
            this.addTrainingFormattedItem(result.data.data)
          }
        })

      }
    },
    searchUser: function (searchQuery) {
      let self = this
      if (this.searchQuery) {
        this.items = [];
        self.pageNumber = 1;
      }
      let filters = _.clone(this.filters)
      this.$set(filters, 'page', this.pageNumber)
      this.$set(filters, 'perPage', this.filters.perPage)
      this.$set(filters, 'search', searchQuery)
      this.$set(filters, 'order_by', this.filters.order_by.id)
      this.$set(filters, 'by_user', this.filters.by_user)
      this.$set(filters, 'hashtag_id', this.filters.selected_hashtag_id)

      let trainingFilters = _.clone(self.trainingFilters)

      this.$set(trainingFilters, 'page', self.pageNumber)
      this.$set(trainingFilters, 'order_by', this.filters.order_by.id)
      this.$set(trainingFilters, 'perPage', this.trainingFilters.perPage)
      this.$set(trainingFilters, 'by_user', this.trainingFilters.by_user)
      this.$set(trainingFilters, 'search', searchQuery)
      this.$set(trainingFilters, 'from_user', this.trainingFilters.from_user)
      this.$set(trainingFilters, 'repeat_id', this.trainingFilters.repeat_id)

      this.$store.dispatch(GET_ITEMS, {
        url: this.restUrl,
        acceptPromise: false,
        filters: filters,
        showLoading: true,
      }).then(result => {
        if (result.status) {
          // self.items = result.data;
          this.setFormattedItems(result.data)
        }
      })
      if (this.CatalogTrainingVisible) {
        this.$store.dispatch(GET_ITEMS, {
          url: this.restTrainingUrl,
          acceptPromise: false,
          filters: trainingFilters,
          showLoading: true
        }).then(result => {
          if (result.status) {
            this.setTrainingFormattedItems(result.data)
          }
        })
      }
    },
    changeToList() {
      this.activeTab = 1;
    },
    changeToCard() {
      this.activeTab = 2;
    },
    handleOnClickCard(link_type, payload, isAssignmentEndDateExpired) {
      // if (isAssignmentEndDateExpired) {
      //   return;
      // }

      if (link_type === 1) {
        this.$router.push({ name: 'user.assignment-detail', params: { id: payload } })
      } else {
        this.$router.push({ name: 'user.eduction-detail', params: { id: payload } })
      }
    },
    timeFromDate(value) {
      const today = new Date()
      var inputDate = new Date(value);
      var todaysDate = new Date();
      // if (inputDate < todaysDate) {
        // moment.locale(this.secureLocalStorage("language"))
        return inputDate ? moment(inputDate).locale(this.secureLocalStorage("language")).format('DD MMMM YYYY') : ''
      // } else {
      //   return moment(value).locale(this.secureLocalStorage("language") === 'en' ?
      //     this.secureLocalStorage("language") + 'gb' : this.secureLocalStorage("language")).fromNow() + this.$t('başlıyor');
      // }
    },
    fixDate(value) {
      return moment().seconds(value).toNow();
    },
    getRecentTags() {
      let self = this;
      self.$store.dispatch(GET_ITEMS, {
        url: self.recentTagsUrl,
        acceptPromise: false,
      }).then(result => {
        if (result.status) {
          self.hashtags = result.data
        }
      })
    },
    clickedRadioButton($event) {
      if ($event && $event.target.checked) {
        if (+$event.target.value === this.filters.selected_hashtag_id) {
          $event.target.checked = false;
          this.filters.selected_hashtag = null;
          this.filters.selected_hashtag_id = null;
          $('.uk-radio').blur();
          this.applyFilter();
        } else {
          this.filters.selected_hashtag_id = Number($event.target.value);
        }
      }
    },
    async applyFilter() {
      this.page_loading = true
      let self = this;
      let filters = _.clone(self.filters)
      let trainingFilters = _.clone(self.trainingFilters)
      self.pageNumber = 1;
      this.$set(filters, 'page', this.pageNumber)
      this.$set(filters, 'perPage', this.filters.perPage)
      this.$set(filters, 'search', this.filters.search)
      this.$set(filters, 'order_by', this.filters.order_by.id)
      this.$set(filters, 'order', this.filters.sort)
      this.$set(filters, 'by_user', this.filters.by_user)
      this.$set(filters, 'hashtag_id', this.filters.selected_hashtag_id)
      this.$set(filters, 'completed', this.filters.completed)
      if (this.filters.completed === true) {
        this.$set(filters, 'with_expired', false)
      } else {
        this.$set(filters, 'with_expired', this.filters.with_expired)
      }
      //this.$set(this.filters, 'with_expired', this.filters.with_expired)
      this.$set(filters, 'repeat_it', this.filters.repeat_it)
      this.$set(filters, 'on_progress', this.filters.on_progress)
      this.$set(trainingFilters, 'page', self.pageNumber)
      this.$set(trainingFilters, 'order_by', this.filters.order_by.id)
      this.$set(trainingFilters, 'perPage', this.trainingFilters.perPage)
      this.$set(trainingFilters, 'by_user', this.trainingFilters.by_user)
      this.$set(trainingFilters, 'from_user', this.trainingFilters.from_user)
      this.$set(trainingFilters, 'repeat_id', this.trainingFilters.repeat_id)

      latestForAssigmentRequest = JSON.stringify(filters);
      latestForTrainingRequest = JSON.stringify(trainingFilters);


      await this.$store.dispatch(GET_ITEMS, {
        url: this.restUrl,
        acceptPromise: false,
        filters: filters,
        showLoading: true
      }).then(result => {
        if (result.status) {
          if (latestForAssigmentRequest === JSON.stringify(filters)) {

            this.setFormattedItems(result.data)
            this.page_loading = false;
          }
        }
      })

      if (this.CatalogTrainingVisible) {
        await this.$store.dispatch(GET_ITEMS, {
          url: this.restTrainingUrl,
          acceptPromise: false,
          filters: trainingFilters,
          showLoading: true
        }).then(result => {
          if (result.status) {
            if (latestForTrainingRequest === JSON.stringify(filters)) {
              this.setTrainingFormattedItems(result.data)
              this.page_loading = false;
            }

          }
        })
      }
    },
    setFilterItem(key, value) {
      this.allItems = [];
      this.pageNumber = 1;
      this.resetPartialFilters()
      this.filters[key] = value;
      this.trainingFilters[key] = value;
      this.applyFilter()
    },
    resetPartialFilters() {
      this.filters.just_assigned = false
      this.filters.completed = false
      this.filters.on_progress = false
      this.filters.repeat_it = false
      this.filters.with_expired = false
      this.filters.order_by = this.defaultFilterOrderBy
      this.trainingFilters.completed = false
      this.trainingFilters.on_progress = false
      this.trainingFilters.just_assigned = false
      this.trainingFilters.repeat_it = false
    },
    setFormattedItems(items) {
      let results = [];
      if (typeof items.data !== "undefined") {
        items.data.forEach((currentItem) => {
          results.push(this.assignmentFormatItem(currentItem));
        });
      }
      items.data = results
      if (this.filters.repeat_it) {
        items.data.sort(function (a, b) {
          return a.order_number - b.order_number;
        });
      }
      this.items = items
      return true;
    },
    addFormattedItem(items) {
      let self = this
      if (typeof items !== "undefined") {
        items.forEach((currentItem) => {
          self.items.data.push(this.assignmentFormatItem(currentItem));
        });
      }
    },
    assignmentFormatItem(item) {
      return {
        id: item.id,
        name: item.name,
        cover_image_link: item.cover_image_link,
        description: item.description ? item.description : "-",
        has_present: item.has_present,
        duration: item.duration,
        time: item.start_date,
        end_date: item.end_date,
        repeatable: item.repeatable,
        order_number: item.order_number,
        bottom_title: this.getBottomTitle(item.completion_percentage),
        completion_percentage: item.completion_percentage,
        is_completed: item.is_completed,
        sub_title: item.is_compulsory == true ? this.$t('assignment.compulsory') : this.$t('assignment.not_compulsory'),
        link_type: 1,
        is_watchable_again: item.is_watchable_again
      }
    },
    setTrainingFormattedItems(items) {

      if (!this.CatalogTrainingVisible) {
        return false;
      }
      let results = [];
      let data = Object.values(items.data);
      data.forEach((currentItem) => {
        results.push(this.trainingFormatItem(currentItem));
      });
      items.data = results
      this.trainingItems = items
      return true;
    },
    addTrainingFormattedItem(items) {
      if (!this.CatalogTrainingVisible) {
        return false;
      }
      let self = this

      if (self.trainingItems.data) {
        let newData = [];
        self.trainingItems.data = newData.concat(self.trainingItems.data)
      } else {
        self.trainingItems.data = self.trainingItems.data;
      }
      items.forEach((currentItem) => {
        self.trainingItems.data.push(this.trainingFormatItem(currentItem));

      });
    },
    trainingFormatItem(item) {
      if (!this.CatalogTrainingVisible) {
        return false;
      }
      return {
        id: item.id,
        name: item.name,
        cover_image_link: item.cover_image_link,
        description: item.description ? item.description : "-",
        duration: item.duration,
        time: item.created_at,
        bottom_title: this.getBottomTitle(item.completion_percentage),
        completion_percentage: item.completion_percentage,
        is_completed: item.is_completed,
        sub_title: this.$t('assignment.not_compulsory'),
        link_type: 2
      }
    },
    getBottomTitle(percentage) {
      if (percentage === 0)
        return this.$t('general.start')
      else if (percentage >= 100) {
        if (this.is_watchable_again && !this.repeatable)
          return this.$t('İzle')
        else if (this.repeatable)
          return this.$t('Yeniden Al')
        else {
          return this.$t('general.detail')
        }
      }
      else {
        return this.$t('general.continue')

      }
      // switch (percentage) {
      //   case 0:
      //     return this.$t('general.start')
      //   case (percentage >= 100):
      //     if (this.is_watchable_again && !this.repeatable)
      //       return this.$t('İzle')
      //     else if (this.repeatable)
      //       return this.$t('Yeniden Al')
      //     else {
      //       return this.$t('Detay')
      //     }
      //   default:
      //     return this.$t('general.continue')
      // }
    },
    getAssignmentCounts() {
      let self = this;
      this.$store.dispatch(GET_COUNTS, {
        url: this.restUrl + '/counts',
        acceptPromise: false,
        showLoading: true
      }).then(result => {
        self.assigmentCounts = result.data;
      })
    },
    init() {
      this.getAssignmentCounts()
      this.getRecentTags()
      this.applyFilter()
    }
  },
  watch: {
    searchQuery: _.debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value) {
        this.applyFilter()
      }
    },
  },
  mounted() {
    this.init();
  }
}
</script>

<style scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

.button_more {
  border-radius: 50% !important;
  height: 70px;
  width: 70px;
  background-color: var(--backgroundColor) !important;

}

.responsive-tab li a.active {
  color: #3e416d;
  opacity: 0.8;
}

.form-group {
  margin-bottom: 0 !important;
}
</style>

<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}
</style>
